.barIcon {
    width: 40px;
    height: 40px;
    color: black;
    z-index: 10;
}

.linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80px;
    height: 80px;
}

#halfclip {
    width: 50%;
    height: 100%;
    right: 0px;
    position: absolute;
    overflow: hidden;
    transform-origin: left center;
}

#container:hover #halfclip {
    animation: cliprotate .25s ease-in-out 1;
    transform: rotate(180deg);
} 

@keyframes cliprotate {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(0deg);}
    50.01% {transform: rotate(180deg);}
    100% {transform: rotate(180deg);}
}

.halfcircle {
    box-sizing: border-box;
    height: 100%;
    right: 0px;
    position: absolute;
    border: solid 1px transparent;
    border-top-color: black;
    //border-left-color: white; uncomment for full circle effect
    border-radius: 50%;
}

#clipped {
    width: 200%;
    transform: rotate(-45deg);
}

#container:hover #clipped {
    transform: rotate(135deg);
    animation: rotate .125s ease-in-out 2;
}

@keyframes rotate {
    0% {transform: rotate(-45deg);}
    100% {transform: rotate(135deg);}
}

#fixed {
    width: 100%;
    transform: rotate(135deg);  
    opacity: 0;
}

#container:hover #fixed {
    opacity: 1;
    animation: showfixed .25s ease-in-out 1;
}

@keyframes showfixed {
    0% {opacity: 0;}
    49.99% {opacity: 0;}
    50% {opacity: 1;}
   100% {opacity: 1;}
}