@import url(../../index.css);

.App {
    text-align: center;
}

.App-header {
    background-color: #76c276;//#c4b05f;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.link-text {
    color: black;
    font-size: 2em;
    font-family: 'Segoe UI';
    text-decoration: none;
    font-weight: bold;
}

